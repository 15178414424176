Header {
  background-color: var(--primary);
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 10px;
  position:fixed;
  width: 100%;
  z-index: 10;
  height: 3rem;
  top:0;
}

.titleHeader {
  text-decoration: none;
  color: white;
  text-shadow: 0.5px 0.5px 0.5px black;
  font-size: x-large;
}

.header:hover {
  color: rgb(231, 220, 242);
}

/*auth navegador register y login*/

.ulAuth {
  display: flex;
}

.liAuth {
  text-decoration: none;
  color: var(--colorLetra);
  font-weight: bold;
  padding: 5px;
  margin: 3px;
  background-color: var(--secondary);
  border-radius: 3px;
  box-shadow: 0.5px 0.5px 0.5px 0.5px black;
  text-shadow: 0.2px 0.2px 0.5px white;
}

.liAuth:hover {
  background-color: rgb(145, 236, 172);
}

.liAuth:active {
  background: linear-gradient(to bottom, transparent, rgb(145, 236, 172));
}
