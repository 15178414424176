@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Montserrat:ital,wght@1,100&family=Open+Sans:wght@300&family=Press+Start+2P&display=swap");

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleLogin {
  padding: 2rem;
  color: var(--primary);
  font-family: "Alex Brush", cursive;
  font-size: 4rem;
  margin-top: 5rem;
  text-shadow: 0.5px 0.5px 0.5px black;
}

.loginForm {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.inputLogin {
  border: none;
  border-bottom: 3px solid var(--primary);
  outline-color: darkorchid;
  background-color: var(--background-color-input);
  color: var(--primary);
  padding: 5px;
  width: 220px;
}

.inputLogin:focus {
  background: linear-gradient(to bottom, transparent, rgb(184, 245, 197));
  border-bottom: 3px solid rgb(199, 157, 239);
}

input::placeholder {
  color: rgb(150, 94, 202, 0.4);
}

.divLoginButton {
  margin-top: 30px;
}

.linkRegistro {
  text-align: center;
  color: var(--primary);
  margin-top: 30px;
  margin-bottom: 20px;
}

.linkRegistro1 {
  text-decoration: none;
  color: var(--primary);
  font-weight: bold;
}

