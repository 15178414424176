* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  --primary: rgb(150, 94, 202);
  --secondary: rgb(76, 224, 126);
  --colorLetra: white;
  --text-shadow: rgb(32, 64, 43);
  --box-shadow: rgb(30, 128, 64);
  --background-color-input: rgb(140, 246, 163);
  --button-background: rgb(141, 54, 222);
  --button-background-active: linear-gradient(
    to bottom,
    transparent,
    rgb(114, 44, 184)
  );
  --text-shadow-button: black;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

}

main {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);
  flex:1;
}

/*Botones */

.buttonCon {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.buttonBot {
  background: var(--primary);
  color: var(--colorLetra);
  border-radius: 5px;
  padding: 0.2rem 2rem;
  border: 0;
  font-weight: bold;
  box-shadow: 0.5px 0.5px 0.5px 0.3px var(--box-shadow);
  text-shadow: 0.1px 0.1px var(--text-shadow-button);
}

.buttonBot:hover {
  background: var(--button-background);
}

.buttonBot:active {
  background: var(--button-background-active);
}

.error {
  color: red;
  margin-top: 10px;
  text-align: center;
  margin: 20px;
}


footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colorLetra);
  font-weight: bold;
  background-color: var(--primary);
  width: 100%;
  height: 2rem;
 
}

/*Modo nocturno*/

main.nigth {
  --backgroundColorNight: #292730;
  --colorLetraNight: black;
  --secondaryNight: rgb(61, 104, 75);
}
main.nigth {
  background-color: var(--backgroundColorNight);
  min-height: 100vh;
}

main.nigth .imputNewLink,
main.nigth .inputEdit,
main.nigth textarea,
main.nigth .inputLogin,
main.nigth .inputRegister,
main.nigth .inputEditPassword {
  color: var(--colorLetraNight);
  background-color: var(--secondaryNight);
}

main.nigth input::placeholder {
  color: rgba(189, 179, 199, 0.4);
}

.modoNocturnoSun {
  margin-top: 10px;
  color: var(--colorLetra);
}

.modoNocturnoMoon {
  margin-top: 10px;
  color: var(--colorLetra);
}



