.newLinkSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tituloNewLink {
  margin-top: 40px;
  margin-bottom: 10px;
  color: var(--primary);
  text-shadow: 0.5px 0.5px var(--text-shadow);
}

.formContenedorNew {
  display: flex;
  flex-direction: column;
  margin: 30px;
}

.formNewLink {
  display: flex;
  padding: 10px;
  flex-direction: column;
  border: solid 2px var(--primary);
  border-radius: 5px;
  color: var(--primary);
  font-weight: bold;
  box-shadow: 1px 1px 2px var(--box-shadow);
  gap: 5px;
}

.labelNewLink {
  text-shadow: 0.3px 0.3px var(--text-shadow);
}

.imputNewLink {
  height: 30px;
  border: none;
  border-bottom: 3px solid var(--primary);
  background-color: var(--background-color-input);
  color: var(--primary);
  outline-color: darkorchid;
  padding: 5px;
}

.imputNewLink:focus {
  background: linear-gradient(to bottom, transparent, rgb(184, 245, 197));
  border-bottom: 3px solid rgb(199, 157, 239);
}

.textareaNewLink {
  border: none;
  min-height: 60px;
  border-bottom: 3px solid var(--primary);
  background-color: var(--background-color-input);
  color: var(--primary);
  outline-color: darkorchid;
  resize: none;
  padding: 5px;
}
.textareaNewLink:focus {
  background: linear-gradient(to bottom, transparent, rgb(184, 245, 197));
  border-bottom: 3px solid rgb(199, 157, 239);
}

.divButton {
  margin-top: 40px;
}
