.userDataSection {
  margin: 40px;
  color: var(--primary);
  display: flex;
  flex-direction: column;
  gap:10px
}

 .userDataTitle {
  text-shadow: 0.5px 0.5px var(--text-shadow);
}

