.sectionRegister {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tituloRegistro {
  margin-top: 5rem;
  padding: 2rem;
  color: var(--primary);
  font-size: 2.5rem;
  text-shadow: 0.5px 0.5px 0.5px var(--text-shadow);
}
.formRegistro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.inputRegister {
  border: none;
  border-bottom: 3px solid var(--primary);
  background-color: var(--background-color-input);
  color: var(--primary);
  padding: 5px;
  outline-color: darkorchid;
  width: 200px;
}

.inputRegister:focus {
  background: linear-gradient(to bottom, transparent, rgb(184, 245, 197));
  border-bottom: 3px solid rgb(199, 157, 239);
}

input::placeholder {
  color: rgb(150, 94, 202, 0.4);
}

.registerDiv {
  margin-top: 30px;
  margin-bottom: 20px;
}

