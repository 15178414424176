.tituloEditPassword {
  margin-top: 40px;
  text-align: center;
  color: var(--primary);
  text-shadow: 0.5px 0.5px var(--text-shadow);
}

.formEditPassword {
  display: flex;
  flex-direction: column;
  margin: 40px;
  justify-content: center;
  color: var(--primary);
}

.editFieldEditPassword {
  display: flex;
  flex-direction: column;
  padding: 30px;
  font-weight: bold;
  border: 2px solid var(--primary);
  border-radius: 5px;
  box-shadow: 1px 1px 2px var(--box-shadow);
  gap: 5px;
}

.labelEditPassword {
  text-shadow: 0.6px 0.4px var(--text-shadow);
}

.inputEditPassword {
  color: var(--primary);
  height: 30px;
  border: none;
  border-bottom: 3px solid var(--primary);
  background-color:var(--background-color-input);
  outline-color: darkorchid;
  padding: 5px;
}

.inputEditPassword:focus {
  background: linear-gradient(to bottom, transparent, rgb(184, 245, 197));
  border-bottom: 3px solid rgb(199, 157, 239);
}

.divButtonEdit {
  margin-top: 40px;
}

@media (min-width: 600px) {
  .formEditPassword {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
