.tituloEditImagen {
  margin-top: 40px;
  text-align: center;
  color: var(--primary);
  text-shadow: 0.5px 0.5px var(--text-shadow);
}

.editImagen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px;
  align-items: center;
}

.editImagenFiel {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: var(--primary);
  font-weight: bold;
  border: 2px solid var(--primary);
  border-radius: 5px;
  box-shadow: 1px 1px 2px var(--box-shadow);
  gap: 10px;
}

.buttonConEditImagen {
  margin-top: 40px;
}

@media (min-width: 600px) {
  .editImagen {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
