.link {
  border: 2px solid var(--primary);
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  align-items: center;
  box-shadow: 1px 1px 2px var(--box-shadow);
  max-width: 600px;
  overflow: hidden;
}

.tituloEnlace {
  margin-bottom: 5px;
  text-shadow: 0.5px 0.5px var(--text-shadow);
  text-align: center;
}

.tituloLink {
  color: var(--primary);
  text-decoration: none;
}
.enlaceNewLink {
  color: var(--primary);
}

.tituloLink:hover,
.enlaceNewLink:hover {
  color: rgb(189, 168, 208);
}

.enlaceLink {
  margin-bottom: 10px;
  text-shadow: 0.5px 0.5px var(--text-shadow);
}

.imgNewlinkCon {
  width: 87%;
  display: flex;
}

.imgNewlink {
  border-radius: 5px;
  width: 100%;
  height: auto;
  display: inline;
  margin: 0 auto;
  padding: 0;
}

.sectionEnlace {
  margin-top: 10px;
  border: 2px solid var(--primary);
  padding: 5px;
  width: 87%;
  border-radius: 5px;
  text-align: center;
  color: var(--primary);
  box-shadow: 1px 1px 2px var(--box-shadow);
}

.descripcionNewLink,
.pDescripcionNewLink,
.linkNewLink {
  text-shadow: 0.1px 0.1px var(--text-shadow);
  margin: 5px;
}

.linkNombreUser {
  text-decoration: none;
  color: var(--primary);
  font-weight: bold;
}

.linkNombreUser:hover {
  color: rgb(189, 168, 208);
}

.votosNewLink {
  margin: 5px 0;
  font-weight: bold;
  text-shadow: 0.1px 0.1px var(--text-shadow);
}

.buttonEnlace {
  margin-top: 20px;
}

 @media (min-width: 600px) {
  .link {
    margin-left: auto;
    margin-right: auto;
  } 

  .imgNewlink {
    width: 60%;
  }

  .sectionEnlace {
    width: 60%;
  }
}
